import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import classnames from "classnames";
import { IoWarning } from "react-icons/io5";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "./App.css";

dayjs.extend(relativeTime);
dayjs.locale("zh-cn");

const base34_chars = "0123456789ABCDEFGHJKLMNPQRSTUVWXYZ";

const MAX_SHOWS = 500;

function base34ToDec(num_str) {
  let dec_num = 0;
  let power = num_str.length - 1;
  for (let i = 0; i < num_str.length; i++) {
    const c = num_str[i];
    dec_num += base34_chars.indexOf(c) * 34 ** power;
    power--;
  }
  return dec_num;
}

function decToBase34(num) {
  if (num === 0) {
    return "0";
  }
  let base34_str = "";
  while (num > 0) {
    const remainder = num % 34;
    base34_str = base34_chars[remainder] + base34_str;
    num = Math.floor(num / 34);
  }
  return base34_str;
}

function generatePlates(start, end) {
  const ret = [];
  for (let n = base34ToDec(start); n <= base34ToDec(end); n++) {
    ret.push(decToBase34(n));
  }
  const aplabet = /[A-Z]/i;
  return ret.filter((plate) => {
    const last3 = plate.slice(-3);
    return !aplabet.test(last3);
  });
}

const legalPlatePattern = /^[a-hj-np-z?*\d]+$/i;

function TimeClicker({ time, choosable = true }) {
  const [relativeDisplay, setRelativeDisplay] = useState(true);
  const timeText = useMemo(() => {
    if (relativeDisplay) {
      return dayjs(time).fromNow();
    }
    return time;
  }, [time, relativeDisplay]);
  return (
    <span
      onClick={() => {
        setRelativeDisplay(!relativeDisplay);
      }}
    >
      {timeText}
      {!choosable && relativeDisplay && <IoWarning className="inline-block" />}
    </span>
  );
}

function App() {
  const [keyword, setKeyword] = useState("");
  const [debounsedInput] = useDebounce(keyword, 250);
  const [numbers, setNumbers] = useState(null);
  const [lastModified, setLastModified] = useState("");

  const getData = async () => {
    const [r1, r2] = await Promise.all([
      fetch("/range.json", { cache: "no-cache" }),
      fetch("/extra_numbers.json", { cache: "no-cache" }),
    ]);
    if (!r1.ok || !r2.ok) {
      alert("数据读取错误，请刷新后重试");
      return;
    }
    if (r1.headers.get("last-modified")) {
      const lastModified = r1.headers.get("last-modified");
      setLastModified(dayjs(lastModified).format("YYYY-MM-DD HH:mm:ss"));
    }
    const plateRange = await r1.json();
    const extraNumbers = await r2.json();
    let modified = null;

    const plates = plateRange.reduce(
      (
        prev,
        {
          Range: range,
          "Released at": releasedAt,
          Type: plateType,
          Admin: source,
        }
      ) => {
        let choosable = true;
        // releasedAt to date object
        if (releasedAt) {
          const parsed = new Date(releasedAt);
          if (parsed) {
            if (!modified || modified < parsed) {
              modified = parsed;
            }
            // 如果 releasedAt 是距离今天0点7天以内的日期，那么就直接返回
            const releasedDate = new Date(
              parsed.getFullYear(),
              parsed.getMonth(),
              parsed.getDate()
            );
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (today - releasedDate < 7 * 24 * 60 * 60 * 1000) {
              choosable = false;
            }
          }
        }
        if (modified) {
          setLastModified(dayjs(modified).format("YYYY-MM-DD HH:mm:ss"));
        }
        const [from, to] = range.split(" ~ ");
        const plates = generatePlates(from, to);
        return [
          ...prev,
          ...plates.map((plate) => ({
            plate,
            releasedAt,
            plateType,
            source,
            choosable,
          })),
        ];
      },
      []
    );
    const numbersLookup = plates.reduce((prev, current, index) => {
      prev[current.plate] = index;
      return prev;
    }, {});
    for (let index = 0; index < extraNumbers.length; index++) {
      const { Plate: plate, Source: source } = extraNumbers[index];
      if (numbersLookup[plate]) {
        const target = plates[numbersLookup[plate]];
        target.admin = `${target.admin}(${source})`;
        continue;
      }
      plates.push({
        plate,
        plateType: "小型新能源汽车",
        source,
        choosable: true,
      });
    }
    setNumbers(plates);
  };

  useEffect(() => {
    getData();
  }, []);

  const pattern = useMemo(() => {
    const trimmed = debounsedInput.trim();
    if (!trimmed) {
      return null;
    }

    const patternStr = trimmed.replaceAll("?", "\\S").replaceAll("*", "\\S+");
    let ret;
    try {
      ret = new RegExp(patternStr, "i");
    } catch (error) {
      return null;
    }

    return ret;
  }, [debounsedInput]);

  const filter = useCallback(
    ({ plate: val }) => {
      if (!pattern) {
        return true;
      }
      return pattern.test(val);
    },
    [pattern]
  );
  const filtered = useMemo(() => {
    if (numbers == null) {
      return [];
    }
    return numbers.filter(filter);
  }, [filter, numbers]);

  return (
    <div className="container mx-auto p-4">
      <div className="card w-96 bg-base-100 shadow-xl">
        <input
          type="text"
          value={keyword}
          onChange={({ target: { value } }) => {
            if (value && !legalPlatePattern.test(value)) {
              return;
            }
            setKeyword(value);
          }}
          placeholder="?可以代替一个字符, * 可以代替多个字符"
          maxLength={7}
          className="input w-full text-center uppercase"
        />
      </div>
      <div className="text-slate-900 text-xs mt-4 font-mono">
        {lastModified.length > 0 && (
          <span>Database updated at: {lastModified}</span>
        )}
      </div>
      <div className="overflow-x-auto">
        {numbers === null && <progress className="progress w-56"></progress>}
        {numbers != null && (
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th>#</th>
                <th>Release date</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {filtered
                .slice(0, MAX_SHOWS)
                .map(({ choosable, plate, releasedAt, source }) => (
                  <tr
                    key={plate}
                    className={classnames(
                      choosable ? "" : "text-gray-300 cursor-not-allowed"
                    )}
                  >
                    <th>
                      <span
                        className={classnames(choosable ? "" : "tooltip")}
                        data-tip={choosable ? "" : "该车牌号码在7天内将被发行"}
                      >
                        {plate}
                      </span>
                    </th>
                    <td>
                      {releasedAt ? (
                        <TimeClicker time={releasedAt} choosable={choosable} />
                      ) : (
                        <span className="text-gray-300">&lt;Unkown&gt;</span>
                      )}
                    </td>
                    <td>{source}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {filtered.length >= MAX_SHOWS && filtered.length >= MAX_SHOWS && (
          <div className="alert alert-info shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="stroke-current flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>最多显示{MAX_SHOWS}条结果, 请调整关键词进行查询.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
